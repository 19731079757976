.content {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  color: white;
  flex-direction: column;
}

.invite-logo {
  height: 80px;
  pointer-events: none;
  margin: 0;
  margin-bottom: 4vmin;
}

.request-invite-text {
  color: white;
  font-size: 20px;
  letter-spacing: 4px;
  font-weight: 300;
}

.input {
  border: none;
  background-color: transparent;
  text-align: center;
  font-size: 16px;
  border-bottom: 1px solid white;
  padding: 10px;
  font-family: 'Roboto', Arial, sans-serif;
  outline: none;
  color: white;
  width: 300px;
}
