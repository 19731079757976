.invite-video,
.invite-texts,
.invite-loading-video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  z-index: 1;
  overflow: hidden;
}

.invite-video {
  animation: fadeIn 1s;
}

.invite-loading-video {
  object-fit: contain;
  background-color: black;
}

.invite-texts {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 2.5s;
  text-transform: uppercase;
}

.cta-wrapper {
  height: auto;
  margin: 20px auto 20px auto;
  width: 100%;
  text-align: center;
}

.qr-code {
  height: auto;
  max-width: 100%;
  width: 100%;
}

.invite-text {
  color: white;
  text-align: center;
}

.invite-logo {
  height: 15vmin;
  pointer-events: none;
  margin: 10vmin 0;
}

.invite-link {
  text-decoration: underline;
  color: white;
}

.one {
  margin-bottom: 10px;
}

.invite-loading-container {
  background-color: black;
}

.invite-error {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.invite-text.five,
.invite-text.two {
  margin-bottom: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.invite-error-text {
  color: white;
  margin-top: 50px;
  text-align: center;
  font-size: calc(5px + 1vmin);
}
