@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/roboto/Roboto-Regular.ttf) format('ttf');
}

html {
  background-color: #000;
}

input {
  border-radius: 0;
  -webkit-appearance: none;
}

pre {
  white-space: pre-wrap;
}

.Promotion {
  height: 85vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Invite {
  height: calc(100vh - calc(100vh - 100%));
  width: 100vw;
  background-color: white;
  overflow: hidden;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
  margin-bottom: 5vmin;
}

.App-header {
  background-color: #000;
  /*#282c34;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', Arial, sans-serif;
  color: white;
}

.App-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  font-size: calc(5px + 1vmin);
  font-family: 'Roboto', Arial, sans-serif;
  color: white;
  outline: none;
  text-align: center;
  width: 150%;
  padding: 10px;
  margin-bottom: 5vmin;
}

.App-button {
  background-color: transparent;
  border: none;
  font-size: calc(5px + 1vmin);
  font-family: 'Roboto', Arial, sans-serif;
  color: white;
  outline: none;
  text-align: center;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.Video-player {
  position: absolute;
  top: 0;
  left: 0;
}

.icons {
  position: absolute;
  z-index: 50;
  right: 0;
  bottom: 0;
  margin-right: 4vmin;
  margin-bottom: 4vmin;
  display: flex;
  gap: 10px;
}

.icon {
  cursor: pointer;
}

.icon a {
  display: block;
  height: 100%;
  width: 100%;
}

